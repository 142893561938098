.to_app_btn {
  width: 140px;
  margin-top: 0.5rem;
  padding: 0 10px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  font-size: 14px;
  border-radius: 5px;
  cursor: pointer;
  border: none;
  background: #5284ff;
  color: white;
}
.com_header {
  display: flex;
  justify-content: center;
  align-items: center;
}
.center-cont {
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.com_home {
  width: 68.75rem;
  margin: 0 auto;
  padding-top: 10px;
  min-height: calc(100vh - 15.64rem);
}
.com_home a {
  color: #000;
  text-decoration: none;
}
.com_home .logo {
  text-align: center;
}
.com_home .logo > img {
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
}
.com_home .text {
  text-align: center;
  margin-top: 1.25rem;
  font-size: 1.625rem;
  font-weight: 700;
}
.com_home .fast-border {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 5rem;
}
.com_home .fast-border .fast-item {
  width: 33.75rem;
  height: auto;
  margin: 0.5rem;
  padding: 1.25rem;
  border: 1px solid #0d87d7;
  border-radius: 0.625rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.com_home .fast-border .fast-item .title {
  font-size: 1.125rem;
  font-weight: 700;
}
.com_home .fast-border .fast-item .box_logo {
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
}
.com_home .fast-border .fast-item .box_logo > img {
  width: 12rem;
}
.com_home .fast-border .fast-item .text {
  font-size: 1.625rem;
  margin-top: 0.625rem;
}
.com_home .fast-border .fast-item .btn {
  width: 12.5rem;
  height: 2.5rem;
  line-height: 2.5rem;
  border-radius: 0.3125rem;
  border: 1px solid #333;
  text-align: center;
  font-size: 1rem;
  cursor: pointer;
  margin-top: 1.25rem;
}
.com_home .fast-border .fast-item .btn_dis {
  cursor: no-drop;
}
.com_home .con {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 3.125rem;
}
.com_home .con .box {
  width: 68rem;
  height: auto;
  margin: 1.5625rem;
  padding: 1.25rem;
  border: 1px solid #0d87d7;
  border-radius: 0.625rem;
  display: flex;
  flex-direction: column;
}
.com_home .con .box .main {
  font-weight: bolder;
  margin-top: 2rem;
  margin-bottom: 0.5rem;
}
.com_home .con .box .title {
  margin-top: 0.5rem;
}
.com_home .bottom-area {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 3.125rem;
}
.com_home .bottom-area .bottom {
  width: 68rem;
  height: auto;
  margin: 1.5625rem;
  padding: 1.25rem;
  border: 1px solid #0d87d7;
  border-radius: 0.625rem;
  display: flex;
  flex-direction: column;
}
.com_home .bottom-area .bottom img {
  width: 100%;
}
.com_home .bottom-area .bottom .condescs {
  margin-top: 0.5rem;
}
.com_home .btn {
  width: 50rem;
  line-height: 2.5rem;
  border-radius: 0.3125rem;
  border: 1px solid #0d87d7;
  text-align: center;
  font-size: 1rem;
  cursor: pointer;
  margin-top: 1.25rem;
}
.com_home .btn_dis {
  cursor: no-drop;
}
@media screen and (max-width: 768px) {
  .com_home {
    width: 100%;
    padding: 0 1.25rem;
    padding-top: 1.125rem;
    background-color: #0b002a;
    color: #fff;
  }
  .com_home > .text {
    text-align: center;
    margin-top: 0.625rem;
    font-size: 20px;
    color: #fff;
  }
  .com_home .fast-border {
    flex-direction: column;
    margin-top: 1.25rem;
  }
  .com_home .fast-border .fast-item {
    width: 100%;
    height: 16.875rem;
    margin: 0 auto;
    padding: 1.25rem;
    margin-bottom: 1.5625rem;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .com_home .fast-border .fast-item .title {
    font-size: 24px;
    color: #555555;
  }
  .com_home .fast-border .fast-item .box_logo > img {
    width: 11rem;
  }
  .com_home .fast-border .fast-item .text {
    font-size: 24px;
    margin-top: 0.9375rem;
    color: #555555;
  }
  .com_home .fast-border .fast-item .btn {
    width: 12.5rem;
    height: 2.5rem;
    line-height: 2.5rem;
    border-radius: 0.625rem;
    font-size: 16px;
    margin-top: 1.25rem;
    color: #555555;
  }
  .com_home .fast-border .fast-item .btn_dis {
    cursor: no-drop;
  }
  .com_home .con {
    flex-direction: column;
    margin-top: 1.25rem;
  }
  .com_home .con .box {
    color: #555555;
    width: 100%;
    margin: 0 auto;
    padding: 1.25rem;
    margin-bottom: 1.5625rem;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .com_home .con .box .title {
    color: #555555;
  }
  .com_home .con .box .text {
    margin-top: 0.9375rem;
  }
  .com_home .con .box .btn {
    width: 12.5rem;
    height: 2.5rem;
    line-height: 2.5rem;
    border-radius: 0.625rem;
    font-size: 16px;
    margin-top: 1.25rem;
    color: #555555;
  }
  .com_home .con .box .btn_dis {
    cursor: no-drop;
  }
  .com_home .bottom-area {
    flex-direction: column;
    margin-top: 1.25rem;
  }
  .com_home .bottom-area .bottom {
    color: #555555;
    width: 100%;
    margin: 0 auto;
    padding: 1.25rem;
    margin-bottom: 1.5625rem;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .com_home .bottom-area .bottom .condescs {
    margin-top: 0.5rem;
    white-space: normal;
    word-wrap: break-word;
  }
}
