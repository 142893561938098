.PubToken {
  width: 100%;
  margin: 0 auto;
  margin-top: 2rem;
  padding-left: 1rem;
  padding-right: 1rem;
  font-size: 14px;
  color: black;
}
.PubToken .mutirow {
  display: flex;
}
.PubToken .mutirow .ant-upload-picture-card-wrapper {
  width: 140px;
}
.PubToken .mutirow .ant-upload-picture-card-wrapper .ant-upload-select-picture-card {
  width: 140px;
  height: 140px;
}
.PubToken .mutirow .right {
  margin-left: 40px;
  width: 90%;
}
.PubToken .linerowmt {
  margin-top: 1.8rem;
}
.PubToken .linerow {
  text-align: start;
  font-weight: 700;
}
.PubToken .linerow .towtitle {
  margin-bottom: 0.1rem;
}
.PubToken .linerow .rowinput {
  margin-bottom: 0.1rem;
}
.PubToken .pub_btn {
  margin-top: 1rem;
}
@media screen and (max-width: 768px) {
  .PubToken {
    color: white;
  }
}
