.ens_handle_btn_disable {
  margin-left: 0.2rem;
  padding: 0 10px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  font-size: 14px;
  border-radius: 5px;
  cursor: pointer;
  background-color: #e5e5e5;
  border: 1px solid #333;
}
.ens_handle_btn {
  margin-left: 0.2rem;
  padding: 0 10px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  font-size: 14px;
  border-radius: 5px;
  cursor: pointer;
  border: none;
  background: #5284ff;
  color: white;
}
.table_btns {
  width: 120px;
}
.main-search-area {
  width: 100%;
  display: flex;
  margin-top: 40px;
  margin-bottom: 40px;
  font-size: 20px;
}
.main-search-area .search-form {
  flex: 1;
  display: flex;
  width: 100%;
  height: 50px;
  border-radius: 5px;
  border: 1px solid #333;
  padding: 0px 20px;
}
.main-search-area .search-form input {
  padding-left: 10px;
  width: 100%;
  border: none;
  border-radius: 0;
  font-family: Overpass;
  font-weight: 100;
}
.main-search-area .search-form input:focus {
  outline: 0;
}
.main-search-area .search-form input::-webkit-input-placeholder {
  color: gray;
}
.main-search-area .search-form .end {
  text-align: center;
  height: 50px;
  line-height: 50px;
}
.main-search-area .earch_button {
  margin-left: 20px;
  border-radius: 5px;
  border: 1px solid #333;
  background: #5284ff;
  color: white;
  font-family: Overpass;
  width: 162px;
  border: none;
  overflow: hidden;
}
.main-search-area .earch_button:hover {
  cursor: pointer;
}
.TweetTotalArea {
  width: 90%;
  margin: 0 auto;
  font-size: 14px;
}
.TweetTotalArea .fast-border {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 2rem;
}
.TweetTotalArea .fast-border .fast-item {
  width: 45rem;
  height: auto;
  padding: 1rem;
  border: 1px solid #0d87d7;
  border-radius: 0.625rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.TweetTotalArea .fast-border .fast-item .user-row-title {
  margin-bottom: 1.5rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-size: 0.9rem;
  font-weight: 800;
}
.TweetTotalArea .fast-border .fast-item .user-row {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.3rem;
  color: black;
}
.TweetTotalArea .fast-border .fast-item .user-row .row-left {
  display: flex;
}
.TweetTotalArea .fast-border .fast-item .user-row .row-left .user-avater {
  width: 2rem;
  height: 2rem;
}
.TweetTotalArea .fast-border .fast-item .user-row .row-left .left-info {
  margin-left: 0.6rem;
  font-size: 0.9rem;
  font-weight: 400;
}
.TweetTotalArea .fast-border .fast-space {
  width: 1rem;
}
@media screen and (max-width: 768px) {
  .table_btns {
    display: none;
  }
  .main-search-area {
    margin: 10px 0px;
    width: 100%;
    font-size: 18px;
  }
  .main-search-area .search-form {
    padding: 0 5px;
  }
  .main-search-area .earch_button {
    width: 80px;
    margin-left: 5px;
  }
  .ant-table {
    background-color: #0b002a;
  }
  .TweetTotalArea {
    width: 100%;
    padding: 0 20px;
    padding-top: 50px;
    background-color: #0b002a;
  }
  .TweetTotalArea .air_invite {
    padding: 16px;
    background-color: #fff;
    color: rgba(0, 0, 0, 0.85);
  }
  .TweetTotalArea .air_invite .title {
    font-size: 16px;
    color: rgba(0, 0, 0, 0.85);
  }
  .TweetTotalArea .fast-border {
    flex-direction: column;
    margin-top: 1.25rem;
  }
  .TweetTotalArea .fast-border .fast-item {
    width: 100%;
    margin: 0 auto;
    padding: 1.25rem;
    margin-bottom: 1.5625rem;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
