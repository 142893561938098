.UrlBtnTitle {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.UrlBtnTitle .subTitle {
  font-weight: bold;
}
.UrlBtnTitle .subButton {
  font-weight: bolder;
  font-size: 0.8rem;
}
