.home {
  width: 68.75rem;
  margin: 0 auto;
  padding: top 100px;
  min-height: calc(100vh - 0.64rem);
}
.home a {
  color: #000;
  text-decoration: none;
}
.home .logo {
  text-align: center;
}
.home .logo > img {
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
}
.home .text {
  text-align: center;
  margin-top: 1.25rem;
  font-size: 1.625rem;
  font-weight: 700;
}
.home .fast-border {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 3.125rem;
}
.home .fast-border .fast-item {
  width: 25rem;
  height: auto;
  margin: 1.5625rem;
  padding: 1.25rem;
  border: 1px solid #0d87d7;
  border-radius: 0.625rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.home .fast-border .fast-item .title {
  font-size: 1.125rem;
  font-weight: 700;
}
.home .fast-border .fast-item .box_logo {
  margin-top: 1.25rem;
}
.home .fast-border .fast-item .box_logo > img {
  width: 9.375rem;
}
.home .fast-border .fast-item .text {
  font-size: 1.625rem;
  margin-top: 0.625rem;
}
.home .fast-border .fast-item .btn {
  width: 12.5rem;
  height: 2.5rem;
  line-height: 2.5rem;
  border-radius: 0.3125rem;
  border: 1px solid #333;
  text-align: center;
  font-size: 1rem;
  cursor: pointer;
  margin-top: 1.25rem;
}
.home .fast-border .fast-item .btn_dis {
  cursor: no-drop;
}
@media screen and (max-width: 768px) {
  .home {
    width: 100%;
    padding: 0 1.25rem;
    padding-top: 3.125rem;
    background-color: #0b002a;
    color: #fff;
  }
  .home .logo {
    display: none;
  }
  .home .logo > img {
    width: 6.25rem;
    height: 6.25rem;
    border-radius: 50%;
  }
  .home > .text {
    text-align: center;
    margin-top: 0.625rem;
    font-size: 20px;
    color: #fff;
  }
  .home .fast-border {
    flex-direction: column;
    margin-top: 1.25rem;
  }
  .home .fast-border .fast-item {
    width: 100%;
    height: 21.875rem;
    margin: 0 auto;
    padding: 1.25rem;
    margin-bottom: 1.5625rem;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .home .fast-border .fast-item .title {
    font-size: 24px;
    color: #555555;
  }
  .home .fast-border .fast-item .box_logo {
    margin-top: 2.5rem;
  }
  .home .fast-border .fast-item .box_logo > img {
    width: 6.25rem;
  }
  .home .fast-border .fast-item .text {
    font-size: 24px;
    margin-top: 0.9375rem;
    color: #555555;
  }
  .home .fast-border .fast-item .btn {
    width: 12.5rem;
    height: 2.5rem;
    line-height: 2.5rem;
    border-radius: 0.625rem;
    font-size: 16px;
    margin-top: 1.25rem;
    color: #555555;
  }
  .home .fast-border .fast-item .btn_dis {
    cursor: no-drop;
  }
}
