.BtcInscribePage {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 50px;
}
.BtcInscribePage .MagicEden {
  width: 100%;
  text-align: left;
  margin-top: 0.6rem;
  font-weight: bolder;
  font-size: 0.8rem;
}
.BtcInscribePage .ant-table .ant-table-container {
  border-radius: 10px;
  overflow: auto;
  border: 1px solid #0e2c46;
}
.BtcInscribePage .ant-table .ant-table-container .ant-table-content table {
  background-color: #fff;
}
.BtcInscribePage .ant-table .ant-table-container .ant-table-content table .ant-table-thead {
  background-color: #0e2c46;
  overflow: auto;
  color: #fff;
}
.BtcInscribePage .ant-table .ant-table-container .ant-table-content table .ant-table-thead tr th {
  text-align: center;
  background-color: transparent;
  color: #fff;
}
.BtcInscribePage .ant-table .ant-table-container .ant-table-content table .ant-table-tbody tr td {
  text-align: start;
  border: none;
}
.BtcInscribePage .btc-buttom-btn {
  display: flex;
  justify-content: end;
}
.BtcInscribePage .btc-buttom-btn .btc-insc-btn {
  margin-top: 10px;
}
.editable-cell {
  position: relative;
}
.editable-cell-input {
  height: 35px;
  line-height: 35px;
  padding: 0 15px;
  text-align: left;
  border: 1px solid #434343;
}
