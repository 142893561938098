.handle_btn_disable {
  margin-left: 0.2rem;
  width: 100px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  font-size: 14px;
  border-radius: 5px;
  cursor: pointer;
  background-color: #e5e5e5;
  border: 1px solid #333;
}
.handle_btn {
  margin-left: 0.2rem;
  width: 100px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  font-size: 14px;
  border-radius: 5px;
  cursor: pointer;
  border: none;
  background: #5284ff;
  color: white;
}
.area_border {
  border: 1px solid #0e2c46;
  padding-bottom: 2rem;
  border-radius: 10px;
  margin-top: 5rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.area_border2 {
  margin-top: 3rem;
  border: 1px solid #0e2c46;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.VoteAirdrop {
  width: 100%;
  margin: 0 auto;
  padding-top: 10px;
}
.VoteAirdrop .to_oldair_area {
  width: 1000px;
  height: auto;
  display: flex;
  justify-content: right;
  margin-top: 20px;
  margin-bottom: 20px;
}
.VoteAirdrop .to_oldair_area .to_old_airdrop {
  width: 300px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  font-size: 14px;
  border-radius: 5px;
  margin-top: 10px;
  right: 50px;
  background: #5284ff;
  color: white;
}
.VoteAirdrop .air_top_log {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
}
.VoteAirdrop .air_top_log .rounded-full {
  width: 6rem;
}
.VoteAirdrop .air_top_log .titles {
  font-size: 1.2rem;
  margin-top: 1rem;
  font-weight: bolder;
}
.VoteAirdrop .air_top_20 {
  margin-top: 20px;
}
.VoteAirdrop .air_top {
  width: 1000px;
  height: auto;
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}
.VoteAirdrop .air_top .ant-tabs {
  width: 100%;
}
.VoteAirdrop .air_top .ant-tabs .ant-tabs-nav::before {
  border-bottom: none;
}
.VoteAirdrop .air_top .air_left {
  width: 100%;
  height: 300px;
  border-radius: 5px;
  border: 1px solid #333;
  padding: 20px 50px;
  position: relative;
}
.VoteAirdrop .air_top .air_left > .title {
  width: 100%;
  font-size: 18px;
  display: flex;
  justify-content: space-between;
}
.VoteAirdrop .air_top .air_left > .title .rule {
  cursor: pointer;
}
.VoteAirdrop .air_top .air_left > .title .ant-image {
  display: none;
}
.VoteAirdrop .air_top .air_left .left_con {
  width: 100%;
  display: flex;
  margin-top: 50px;
  justify-content: space-between;
}
.VoteAirdrop .air_top .air_left .left_con .item {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.VoteAirdrop .air_top .air_left .left_con .item .num {
  font-size: 24px;
}
.VoteAirdrop .air_top .air_left .left_con .item .tetx {
  font-size: 18px;
  margin-top: 20px;
}
.VoteAirdrop .air_top .air_left .left_con .item .handle_btn {
  width: 320px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  font-size: 14px;
  border-radius: 5px;
  background: #5284ff;
  color: white;
  margin-top: 30px;
  cursor: pointer;
}
.VoteAirdrop .air_top .air_left .left_con .item .handle_btn_dis {
  background-color: #e5e5e5;
  border: 1px solid #333;
  color: black;
}
.VoteAirdrop .air_top .air_left .rule_div {
  width: 100px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  font-size: 14px;
  border-radius: 5px;
  border: 1px solid #333;
  margin-top: 10px;
  cursor: pointer;
  position: absolute;
  right: 50px;
}
.VoteAirdrop .air_top .air_left .to_ens_div {
  width: 220px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  font-size: 14px;
  border-radius: 5px;
  border: 1px solid #333;
  margin-top: 10px;
  cursor: pointer;
  position: absolute;
  right: 50px;
  background-color: #e5e5e5;
  color: black;
}
.VoteAirdrop .air_top .air_left .to_ens_div_yes {
  width: 220px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  font-size: 14px;
  border-radius: 5px;
  margin-top: 10px;
  cursor: pointer;
  position: absolute;
  right: 50px;
  background: #5284ff;
  color: white;
}
.VoteAirdrop .air_top_mobile {
  width: 100%;
  height: auto;
  display: none;
  justify-content: space-between;
  margin-top: 50px;
}
.VoteAirdrop .air_top_mobile .ant-tabs {
  width: 100%;
}
.VoteAirdrop .air_top_mobile .ant-tabs .ant-tabs-nav::before {
  border-bottom: none;
}
.VoteAirdrop .air_top_mobile .air_left {
  width: 100%;
  height: 300px;
  border-radius: 5px;
  border: 1px solid #333;
  padding: 20px 50px;
  position: relative;
}
.VoteAirdrop .air_top_mobile .air_left > .title {
  width: 100%;
  font-size: 18px;
  display: flex;
  justify-content: space-between;
}
.VoteAirdrop .air_top_mobile .air_left > .title .rule {
  cursor: pointer;
}
.VoteAirdrop .air_top_mobile .air_left > .title .ant-image {
  display: none;
}
.VoteAirdrop .air_top_mobile .air_left .left_con {
  width: 100%;
  display: flex;
  margin-top: 50px;
  justify-content: space-between;
}
.VoteAirdrop .air_top_mobile .air_left .left_con .item {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.VoteAirdrop .air_top_mobile .air_left .left_con .item .num {
  font-size: 24px;
}
.VoteAirdrop .air_top_mobile .air_left .left_con .item .tetx {
  font-size: 18px;
  margin-top: 20px;
}
.VoteAirdrop .air_top_mobile .air_left .left_con .item .handle_btn {
  width: 180px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  font-size: 14px;
  border-radius: 5px;
  border: 1px solid #333;
  margin-top: 30px;
  cursor: pointer;
}
.VoteAirdrop .air_top_mobile .air_left .left_con .item .handle_btn_dis {
  background-color: #e5e5e5;
  border: 1px solid #333;
  width: 180px;
  height: 40px;
  text-align: center;
  line-height: 2.5rem;
  font-size: 14px;
  border-radius: 5px;
  margin-top: 0;
}
.VoteAirdrop .air_top_mobile .air_left .rule_div {
  width: 100px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  font-size: 14px;
  border-radius: 5px;
  border: 1px solid #333;
  margin-top: 10px;
  cursor: pointer;
  position: absolute;
  right: 50px;
}
.VoteAirdrop .air_invite {
  width: 1000px;
  padding: 20px 50px;
  border-radius: 5px;
  border: 1px solid #333;
}
.VoteAirdrop .air_invite .title {
  font-size: 18px;
}
.VoteAirdrop .air_invite .copy-row {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.VoteAirdrop .air_invite .copy-row .invite_link {
  font-size: 13px;
}
.VoteAirdrop .air_invite .copy-row .copy-btns {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.VoteAirdrop .air_invite .copy-row .copy-btns .copy_btn {
  width: 100px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  font-size: 14px;
  border-radius: 5px;
  cursor: pointer;
  background: #5284ff;
  color: white;
  margin-right: 0.5rem;
}
.VoteAirdrop .info_div {
  width: 100%;
  border-radius: 5px;
  border: 1px solid #333;
  display: flex;
  padding: 20px 50px;
  margin-top: 50px;
}
.VoteAirdrop .info_div .info_area {
  flex-direction: row;
  align-items: center;
}
.VoteAirdrop .info_div .info_area .row-text {
  margin-top: 20px;
  font-size: 16px;
  padding-right: 50px;
}
.VoteAirdrop .no_reg {
  width: 100%;
  border-radius: 5px;
  border: 1px solid #333;
  display: flex;
  flex-direction: column;
  padding: 20px 50px;
}
.VoteAirdrop .no_reg .no_reg_top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.VoteAirdrop .no_reg .no_reg_top .text {
  flex: 1;
  font-size: 16px;
  padding-right: 50px;
}
.VoteAirdrop .no_reg .no_reg_top .handle_btn {
  width: 100px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  font-size: 14px;
  border-radius: 5px;
  background: #5284ff;
  color: white;
  cursor: pointer;
}
.VoteAirdrop .no_reg .error_msg {
  margin-top: 20px;
}
.VoteAirdrop .no_reg .error_msg .tetx {
  font-size: 16px;
}
.VoteAirdrop .history {
  width: 1000px;
  margin-top: 50px;
}
.VoteAirdrop .history .history_t {
  font-size: 18px;
}
.VoteAirdrop .history .table {
  width: 100%;
  margin-top: 10px;
  border: 1px solid #0e2c46;
  font-size: 16px;
  border-radius: 5px;
}
.VoteAirdrop .history .table .thead {
  width: 100%;
  height: 60px;
  background-color: #0e2c46;
  color: #fff;
}
.VoteAirdrop .history .table .thead .tr {
  width: 100%;
  display: flex;
}
.VoteAirdrop .history .table .thead .tr .td {
  width: 50%;
  text-align: center;
  line-height: 60px;
  color: #fff;
}
.VoteAirdrop .history .table .tbody {
  height: 20.25rem;
  overflow: auto;
}
.VoteAirdrop .history .table .tbody::-webkit-scrollbar {
  width: 0;
}
.VoteAirdrop .history .table .tbody .tr {
  width: 100%;
  display: flex;
  height: 50px;
  border-bottom: 1px solid #e5e5e5;
}
.VoteAirdrop .history .table .tbody .tr .td {
  width: 50%;
  text-align: center;
  line-height: 50px;
}
.VoteAirdrop .history .table_mobile {
  display: none;
  width: 100%;
  margin-top: 10px;
  border: 1px solid #0e2c46;
  font-size: 16px;
  border-radius: 5px;
}
.VoteAirdrop .history .table_mobile .thead {
  width: 100%;
  height: 60px;
  background-color: #0e2c46;
  color: #fff;
}
.VoteAirdrop .history .table_mobile .thead .tr {
  width: 100%;
  display: flex;
}
.VoteAirdrop .history .table_mobile .thead .tr .td {
  width: 50%;
  text-align: center;
  line-height: 60px;
  color: #fff;
}
.VoteAirdrop .history .table_mobile .tbody {
  height: 31.25rem;
  overflow: auto;
}
.VoteAirdrop .history .table_mobile .tbody::-webkit-scrollbar {
  width: 0;
}
.VoteAirdrop .history .table_mobile .tbody .tr {
  width: 100%;
  display: flex;
  height: 50px;
  border-bottom: 1px solid #e5e5e5;
}
.VoteAirdrop .history .table_mobile .tbody .tr .td {
  width: 50%;
  text-align: center;
  line-height: 50px;
}
@media screen and (max-width: 768px) {
  .air_top_20 {
    margin-top: 5px;
  }
  .area_border {
    border: 2px solid #0e2c46;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 1rem;
    border-radius: 10px;
  }
  .area_border2 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    border: 2px solid #0e2c46;
    padding-left: 1rem;
    padding-right: 1rem;
    border-radius: 10px;
  }
  .VoteAirdrop {
    width: 100%;
    padding: 0 20px;
    padding-top: 50px;
    background-color: #0b002a;
  }
  .VoteAirdrop .to_oldair_area {
    width: 100%;
  }
  .VoteAirdrop .history {
    width: 100%;
  }
  .VoteAirdrop .air_invite {
    width: 100%;
  }
  .VoteAirdrop .air_top_log {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 2rem;
  }
  .VoteAirdrop .air_top_log .rounded-full {
    width: 5rem;
  }
  .VoteAirdrop .air_top_log .titles {
    color: white;
  }
  .VoteAirdrop .no_reg {
    background-color: #fff;
  }
  .VoteAirdrop .air_top {
    display: none;
    flex-direction: column;
    height: auto;
  }
  .VoteAirdrop .air_top .ant-tabs-tab .ant-tabs-tab-btn {
    color: #fff;
  }
  .VoteAirdrop .air_top .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    font-size: 18px;
  }
  .VoteAirdrop .air_top .air_left {
    width: 100%;
    padding: 20px;
    background-color: #fff;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
  .VoteAirdrop .air_top .air_left > .title {
    font-size: 16px;
  }
  .VoteAirdrop .air_top .air_left .left_con {
    flex-direction: column;
    margin-top: 0;
  }
  .VoteAirdrop .air_top .air_left .left_con .item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 1.25rem 0;
  }
  .VoteAirdrop .air_top .air_left .left_con .item .num {
    font-size: 20px;
  }
  .VoteAirdrop .air_top .air_left .left_con .item .tetx {
    font-size: 16px;
    margin-top: 0;
  }
  .VoteAirdrop .air_top .air_left .left_con .item .handle_btn_dis {
    background-color: #e5e5e5;
    border: none;
    width: 6.85rem;
    height: 2.5rem;
    line-height: 2.5rem;
    font-size: 14px;
    margin-top: 0;
  }
  .VoteAirdrop .air_top .air_left .left_con .item .handle_btn {
    width: 120px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    font-size: 14px;
    border-radius: 5px;
    border: 1px solid #333;
    margin-top: 20px;
    cursor: pointer;
  }
  .VoteAirdrop .air_top .air_left .rule_div {
    font-size: 16px;
    position: absolute;
    right: 0;
    bottom: 0;
  }
  .VoteAirdrop .air_top_mobile {
    flex-direction: column;
    height: auto;
    display: flex;
  }
  .VoteAirdrop .air_top_mobile .ant-tabs-tab .ant-tabs-tab-btn {
    color: #fff;
    font-size: 16px;
  }
  .VoteAirdrop .air_top_mobile .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    font-size: 16px;
    color: #fff;
  }
  .VoteAirdrop .air_top_mobile .air_left {
    width: 100%;
    height: 21.875rem;
    padding: 30px 20px 80px 20px;
    background-color: #fff;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
  .VoteAirdrop .air_top_mobile .air_left > .title {
    font-size: 16px;
  }
  .VoteAirdrop .air_top_mobile .air_left .left_con {
    flex-direction: column;
    margin-top: 0;
  }
  .VoteAirdrop .air_top_mobile .air_left .left_con .item {
    display: flex;
    flex-direction: column;
    margin: 1.25rem 0;
  }
  .VoteAirdrop .air_top_mobile .air_left .left_con .item .line_one {
    margin-bottom: 1rem;
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .VoteAirdrop .air_top_mobile .air_left .left_con .item .line_one .num {
    font-size: 20px;
  }
  .VoteAirdrop .air_top_mobile .air_left .left_con .item .line_one .tetx {
    margin-left: 2.2rem;
    font-size: 16px;
    margin-top: 0;
  }
  .VoteAirdrop .air_top_mobile .air_left .left_con .item .line_two {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
  .VoteAirdrop .air_top_mobile .air_left .left_con .item .line_two .handle_btn {
    width: 6.25rem;
    height: 2.5rem;
    line-height: 2.5rem;
    font-size: 14px;
    margin-top: 0;
    background: #5284ff;
    color: white;
    border: none;
  }
  .VoteAirdrop .air_top_mobile .air_left .rule_div {
    font-size: 16px;
    position: absolute;
    right: 0.625rem;
    bottom: 0.625rem;
  }
  .VoteAirdrop .air_top_mobile .air_left .to_ens_div {
    width: 220px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    font-size: 16px;
    position: absolute;
    right: 0.625rem;
    bottom: 0.625rem;
    background-color: #e5e5e5;
    color: black;
    border-radius: 5px;
    border: 1px solid #333;
  }
  .VoteAirdrop .air_top_mobile .air_left .to_ens_div_yes {
    width: 220px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    font-size: 16px;
    position: absolute;
    right: 0.625rem;
    bottom: 0.625rem;
    background: #5284ff;
    color: white;
    border-radius: 5px;
  }
  .VoteAirdrop .info_div {
    padding: 16px;
    background-color: #fff;
    color: rgba(0, 0, 0, 0.85);
  }
  .VoteAirdrop .info_div .info_area {
    font-size: 16px;
    color: rgba(0, 0, 0, 0.85);
  }
  .VoteAirdrop .info_div .info_area .row-text {
    padding-right: 0px;
  }
  .VoteAirdrop .air_invite {
    padding: 16px;
    background-color: #fff;
    color: rgba(0, 0, 0, 0.85);
  }
  .VoteAirdrop .air_invite .title {
    font-size: 16px;
    color: rgba(0, 0, 0, 0.85);
  }
  .VoteAirdrop .air_invite .copy-row {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
  }
  .VoteAirdrop .air_invite .copy-row .invite_link {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.85);
    word-break: break-word;
  }
  .VoteAirdrop .air_invite .copy-row .copy-btns {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 0.3rem;
  }
  .VoteAirdrop .air_invite .copy-row .copy-btns .copy_btn {
    width: auto;
    padding: 0 1.25rem;
    height: 2.5rem;
    line-height: 2.5rem;
    font-size: 14px;
  }
  .VoteAirdrop .no_reg {
    padding: 20px 10px;
  }
  .VoteAirdrop .no_reg .no_reg_top .text {
    padding-right: 1.25rem;
  }
  .VoteAirdrop .no_reg .no_reg_top .handle_btn {
    width: 5rem;
    height: 2.5rem;
    line-height: 2.5rem;
    font-size: 14px;
  }
  .VoteAirdrop .history {
    overflow: hidden;
  }
  .VoteAirdrop .history .history_t {
    font-size: 16px;
    color: #fff;
  }
  .VoteAirdrop .history .table {
    display: none;
  }
  .VoteAirdrop .history .table_mobile {
    display: block;
    font-size: 16px;
    border-radius: 10px;
    background-color: #fff;
    overflow: auto;
  }
  .VoteAirdrop .history .table_mobile::-webkit-scrollbar {
    width: 0;
  }
  .VoteAirdrop .history .table_mobile .thead {
    width: 31.25rem;
    height: 4.375rem;
    color: #fff;
  }
  .VoteAirdrop .history .table_mobile .thead .tr {
    width: 100%;
    height: 4.375rem;
    display: flex;
  }
  .VoteAirdrop .history .table_mobile .thead .tr .one_td {
    display: flex;
    flex-direction: column;
    justify-content: center;
    line-height: 1.5rem;
  }
  .VoteAirdrop .history .table_mobile .tbody {
    width: 31.25rem;
    min-height: 6.25rem;
  }
  .VoteAirdrop .history .table_mobile .tbody .tr {
    height: 4.4rem;
  }
  .VoteAirdrop .history .table_mobile .tbody .tr .one_td {
    display: flex;
    flex-direction: column;
    justify-content: center;
    line-height: 1.66rem;
  }
}
