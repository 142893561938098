.ensadmin {
  min-height: calc(100vh - 10.25rem);
  padding-top: 1.25rem;
}
.ensadmin .table {
  width: 62.5rem;
  margin: 0 auto;
  border: 1px solid #333;
  border-radius: 0.3125rem;
}
.ensadmin .table .thead .tr,
.ensadmin .table .tbody .tr {
  width: 100%;
  height: 3rem;
  font-size: 0.8rem;
  line-height: 3rem;
  display: flex;
  justify-content: space-between;
}
.ensadmin .table .thead .tr .td,
.ensadmin .table .tbody .tr .td {
  text-align: center;
  flex: 1;
}
.ensadmin .table .thead .tr .time,
.ensadmin .table .tbody .tr .time {
  flex: 1;
}
.ensadmin .table .thead .tr .address,
.ensadmin .table .tbody .tr .address {
  flex: 2;
  color: red;
}
.ensadmin .table .tbody .tr {
  border-top: 1px solid #333;
}
