.token_selection .show_token {
  display: flex;
  align-items: center;
  color: #222222;
  height: 36px;
  border: 1px solid #f2f2f2;
  padding: 0 12px 0 5px;
  border-radius: 18px;
  background: #fff;
}
.token_selection .show_token .selected_token {
  display: flex;
  align-items: center;
}
.token_selection .show_token .selected_token > img {
  width: 28px;
  height: 28px;
  margin-right: 5px;
}
.token_selection .show_token_no_data {
  padding: 0 12px;
  background: #fc72ff;
  color: #fff;
  cursor: pointer;
  border-radius: 18px;
  border: none;
}
.token_selection .show_token_no_data:hover {
  background: #fd3cfe;
}
.token_selection .show_token_no_data:focus,
.token_selection .show_token_no_data:active {
  transform: scale(0.98);
  opacity: 0.75;
}
.token_selection .arrow_icon {
  width: 26px;
  height: 26px;
  transform: rotate(270deg);
}
.token_modal {
  width: 400px !important;
}
.token_modal .ant-modal-content {
  padding: 16px 0 !important;
}
.token_modal .token_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 18px;
  padding: 0 16px;
  color: #222;
}
.token_modal .token_header .closure_icon {
  width: 24px;
  height: 24px;
  cursor: pointer;
}
.token_modal .search_bar_con {
  padding: 0 16px;
}
.token_modal .search_bar_con .search_bar {
  width: 100%;
  height: 48px;
  margin-top: 10px;
  display: flex;
  align-items: center;
  background: #f9f9f9;
  padding: 0 16px;
  border-radius: 24px;
}
.token_modal .search_bar_con .search_bar .search_icon {
  width: 20px;
  height: 20px;
}
.token_modal .search_bar_con .search_bar .search_input {
  border: none;
  outline: none;
  background: transparent;
  margin-left: 5px;
  font-size: 16px;
}
.token_modal .search_bar_con .search_bar .search_input::placeholder {
  color: #bfbfbf;
}
.token_modal .token_list {
  margin-top: 10px;
  max-height: 408px;
  overflow-y: auto;
}
.token_modal .token_list::-webkit-scrollbar {
  width: 16px;
}
.token_modal .token_list::-webkit-scrollbar-thumb {
  background-color: #f4f4f4;
}
.token_modal .token_list .token_list_item {
  height: 68px;
  display: flex;
  align-items: center;
  padding: 16px;
}
.token_modal .token_list .token_list_item:hover {
  background: #f9f9f9;
  cursor: pointer;
}
.token_modal .token_list .token_list_item .item_icon {
  width: 40px;
  height: 40px;
  margin-right: 12px;
}
.token_modal .token_list .token_list_item .item_icon > img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.token_modal .token_list .token_list_item .item_info {
  display: flex;
  flex-direction: column;
}
.token_modal .token_list .token_list_item .item_info .item_name {
  font-size: 18px;
  line-height: 22px;
  color: #222222;
}
.token_modal .token_list .token_list_item .item_info .item_symbol {
  font-size: 14px;
  line-height: 18px;
  color: #7d7d7d;
}
