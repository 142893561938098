.swapfixedcoin {
  padding-top: 20px;
  width: 100%;
}
.swapfixedcoin .swapfixedcoin_box {
  width: 80%;
  margin: 0 auto;
  border-radius: 10px;
  padding: 15px;
}
.swapfixedcoin .swapfixedcoin_box .swap_tab {
  display: flex;
  gap: 10px;
}
.swapfixedcoin .swapfixedcoin_box .swap_tab .from_btn {
  flex: 1;
  background: #D3D3D3;
  border-radius: 5px;
  border: 1px solid #D3D3D3;
  height: 40px;
  text-align: center;
  line-height: 40px;
  color: white;
  cursor: pointer;
}
.swapfixedcoin .swapfixedcoin_box .swap_tab .btn_active {
  background: white;
  color: #20292c;
}
.swapfixedcoin .swapfixedcoin_box .explain {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}
.swapfixedcoin .swapfixedcoin_box .explain div {
  padding: 5px 0;
  font-size: 14px;
  color: #878d99;
}
.swapfixedcoin .swapfixedcoin_box .explain div:hover {
  cursor: pointer;
}
.swapfixedcoin .swapfixedcoin_box .input_box {
  margin-top: 45px;
}
.swapfixedcoin .swapfixedcoin_box .input_box .input_info {
  height: 40px;
  display: flex;
  align-items: center;
  border: 1px solid #878d99;
  border-radius: 5px;
}
.swapfixedcoin .swapfixedcoin_box .input_box .input_info .input {
  flex: 1;
  height: 100%;
  background: transparent;
  border: none;
  outline: none;
  color: #878d99;
  font-size: 16px;
  padding: 0 10px;
}
.swapfixedcoin .swapfixedcoin_box .input_box .input_info .input::-webkit-inner-spin-button,
.swapfixedcoin .swapfixedcoin_box .input_box .input_info .input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.swapfixedcoin .swapfixedcoin_box .input_box .input_info .symbol {
  font-size: 16px;
  color: black;
  margin-right: 10px;
}
.swapfixedcoin .swapfixedcoin_box .input_box .input_info .icon {
  width: 17px;
  height: 17px;
  margin-right: 10px;
  border-radius: 50%;
  border: 1px solid #878d99;
}
.swapfixedcoin .swapfixedcoin_box .input_box .input_default {
  margin-top: 10px;
  display: flex;
  gap: 3px;
}
.swapfixedcoin .swapfixedcoin_box .input_box .input_default > div {
  padding: 5px 10px;
  background: #1b1d27;
  border-radius: 5px;
  font-size: 14px;
  color: #878d99;
  cursor: pointer;
}
.swapfixedcoin .swapfixedcoin_box .swap_btn {
  width: 100%;
  height: 40px;
  margin-top: 20px;
  background: white;
  border: 1px solid #D3D3D3;
  border-radius: 5px;
  line-height: 40px;
  text-align: center;
  cursor: pointer;
}
@media screen and (max-width: 768px) {
  .swapfixedcoin {
    padding: 0 10px;
    padding-top: 10px;
    width: 100%;
  }
  .swapfixedcoin .swapfixedcoin_box {
    max-width: 500px;
    width: 100%;
  }
  .swapfixedcoin .swapfixedcoin_box .input_box .input_info .input {
    width: 80%;
  }
}
