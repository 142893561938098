.TwtInfosArea {
  display: flex;
  justify-content: end;
}
.TwtInfosArea .TwtInfos {
  width: 7rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.TwtInfosArea .TwtInfos .TwtImg .font-poppins .TwtImgAvater {
  width: 6rem;
  height: 6rem;
}
.TwtInfosArea .TwtInfos .TwtEth {
  display: flex;
  margin-top: 2rem;
  font-weight: bolder;
}
.TwtInfosArea .TwtInfos .TwtEth .font-poppins > img {
  width: 1.2rem;
}
