.invite {
  width: 75rem;
  min-height: calc(100vh - 10.3125rem);
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.invite .box {
  margin-bottom: 2.2rem;
  width: 50rem;
  height: auto;
  border-radius: 0.3125rem;
  border: 1px solid #333;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3.125rem;
}
.invite .box .title {
  font-size: 1.5rem;
  word-break: break-word;
  text-align: center;
}
.invite .box .rowtitle {
  font-size: 1rem;
  word-break: break-word;
  text-align: center;
  font-weight: bolder;
}
.invite .box .row {
  text-align: left;
  margin-top: 1rem;
  width: 100%;
}
.invite .box .lintbtn {
  width: 100%;
  margin-top: 6.25rem;
  display: flex;
  justify-content: space-around;
}
.invite .box .lintbtn a {
  color: #000;
  text-decoration: none;
}
.invite .box .lintbtn .cancel,
.invite .box .lintbtn .confirm {
  width: 6.25rem;
  height: 2.5rem;
  line-height: 2.5rem;
  text-align: center;
  font-size: 0.875rem;
  border-radius: 0.3125rem;
  border: 1px solid #333;
  margin-top: 1.25rem;
  cursor: pointer;
}
@media screen and (max-width: 768px) {
  .invite {
    width: 100%;
  }
  .invite .box {
    width: 90%;
    height: auto;
    padding: 1.5625rem;
  }
  .invite .box .title {
    font-size: 20px;
    text-align: center;
    word-break: break-word;
  }
  .invite .box .btn {
    margin-top: 1.875rem;
  }
  .invite .box .btn .cancel,
  .invite .box .btn .confirm {
    width: 6.25rem;
    height: 1.875rem;
    line-height: 1.875rem;
    font-size: 14px;
  }
}
