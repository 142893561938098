.send_twitter_btn {
  margin-left: 0.2rem;
  padding: 0.5rem 1.5rem;
  text-align: center;
  font-size: 14px;
  border-radius: 5px;
  cursor: pointer;
  border: none;
  background: #5284ff;
  color: white;
}
.table_btns {
  width: 120px;
}
.marginLeft {
  margin-left: 10px;
}
.marginRight {
  margin-right: 10px;
}
.login_button {
  width: 20rem;
  min-height: 3rem;
  border-radius: 5px;
  border: 1px solid #333;
  background: #5284ff;
  color: white;
  font-family: Overpass;
  border: none;
  overflow: hidden;
}
.login_button:hover {
  cursor: pointer;
}
.login-info .unlogin {
  display: flex;
  justify-content: center;
  align-content: center;
}
.login-info .project-divs .user-area {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.login-info .project-divs .user-area .small-text {
  font-size: 10px;
  font-weight: bold;
  margin-left: 0.6rem;
  width: 50%;
}
.login-info .project-divs .user-area .user-info {
  display: flex;
  flex-direction: column;
}
.login-info .project-divs .user-area .user-info .row-left {
  display: flex;
}
.login-info .project-divs .user-area .user-info .row-left .user-avater {
  width: 6rem;
  height: 6rem;
}
.login-info .project-divs .user-area .user-info .row-left .user-infos {
  display: flex;
}
.login-info .project-divs .user-area .user-info .row-left .user-infos .left-info {
  margin-left: 0.6rem;
  font-size: 0.9rem;
  font-weight: 400;
}
.login-info .project-divs .user-area .user-info .row-left .user-infos .right-info {
  margin-left: 1rem;
}
.login-info .project-divs .user-area .user-info .eth-area {
  display: flex;
  margin-top: 0.25rem;
}
.login-info .project-divs .user-area .user-info .eth-area > img {
  width: 1.2rem;
  margin-right: 1rem;
}
.login-info .project-divs .user-area .user-info .eth-area .proj-total {
  margin-left: 2rem;
}
.login-info .project-divs .user-area .user-info .eth-area .eth-name {
  margin-left: 0.3rem;
  text-decoration-line: underline;
}
.login-info .project-divs .user-area .user-info .eth-area .eth-name:hover {
  cursor: pointer;
  text-decoration-line: underline;
  color: blue;
}
.login-info .project-divs .user-area .right-info {
  align-items: end;
}
.air_invite {
  margin-top: 3rem;
  width: 100%;
}
.air_invite .area_row_input {
  display: flex;
  margin-top: 0.2rem;
  height: 40px;
  line-height: 40px;
  align-items: center;
}
.air_invite .area_row_input .modal_input {
  margin-left: 0.2rem;
  border: 0.01rem solid gray;
  height: 40px;
  line-height: 40px;
  border-radius: 0.1rem;
  background-color: white;
  color: black;
  padding-left: 0.2rem;
}
.air_invite .area_row_input .modal_progress {
  margin-left: 0.1rem;
}
.air_invite .area_check_view .title {
  margin-top: 0.3rem;
  display: flex;
  height: 40px;
  line-height: 40px;
}
.air_invite .area_check_view .row {
  display: flex;
  margin-top: 0.2rem;
  margin-left: 1rem;
  line-height: 24px;
}
.air_invite .area_check_view .row .minWidth {
  width: 280px;
}
.air_invite .area_check_view .row .modal_progress {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 0.2rem;
}
.rule_modal_box .ant-modal-content .ant-modal-body .title {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 0.5rem;
  justify-content: center;
  font-size: 20px;
}
.rule_modal_box .ant-modal-content .ant-modal-body .send_tw_dialog {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
  font-size: 20px;
}
.rule_modal_box .ant-modal-content .ant-modal-body .send_tw_dialog .search-row {
  margin-top: 15px;
  font-size: 1rem;
}
.rule_modal_box .ant-modal-content .ant-modal-body .send_tw_dialog .search-form {
  margin-top: 10px;
  flex: 1;
  display: flex;
  width: 100%;
  height: 50px;
  border-radius: 5px;
  border: 1px solid #333;
  padding: 0px 20px;
  font-size: 1rem;
}
.rule_modal_box .ant-modal-content .ant-modal-body .send_tw_dialog .search-form input {
  padding-left: 10px;
  width: 100%;
  border: none;
  border-radius: 0;
  font-family: Overpass;
  font-weight: 100;
  font-size: 1rem;
}
.rule_modal_box .ant-modal-content .ant-modal-body .send_tw_dialog .search-form input:focus {
  outline: 0;
}
.rule_modal_box .ant-modal-content .ant-modal-body .send_tw_dialog .search-form input::-webkit-input-placeholder {
  color: gray;
}
.rule_modal_box .ant-modal-content .ant-modal-body .send_tw_dialog .search-form .end {
  text-align: center;
  height: 50px;
  line-height: 50px;
}
.rule_modal_box .ant-modal-content .ant-modal-body .send_tw_dialog .search-form-area {
  height: 100px;
}
.rule_modal_box .ant-modal-content .ant-modal-body .send_tw_dialog .search-text {
  font-size: 14px;
  margin-top: 0.5rem;
}
.rule_modal_box .ant-modal-content .ant-modal-body .send_tw_dialog .earch_button {
  margin-left: 20px;
  border-radius: 5px;
  border: 1px solid #333;
  background: #5284ff;
  color: white;
  font-family: Overpass;
  width: 162px;
  border: none;
  overflow: hidden;
}
.rule_modal_box .ant-modal-content .ant-modal-body .send_tw_dialog .earch_button:hover {
  cursor: pointer;
}
.rule_modal_box .ant-modal-content .ant-modal-body .box .vote_type {
  margin-bottom: 0.5rem;
}
.rule_modal_box .ant-modal-content .ant-modal-body .box .vote_year {
  margin-bottom: 0.5rem;
}
.rule_modal_box .ant-modal-content .ant-modal-body .box .vote_price {
  margin-bottom: 0.5rem;
}
@media screen and (max-width: 768px) {
  .project-divs .project-divs .user-area .user-info {
    font-size: 10px;
  }
  .login_button {
    width: 10rem;
    padding: 0.5rem;
  }
  .air_invite {
    margin-top: 1rem;
    margin-bottom: 1rem;
    width: 100%;
    padding: 16px;
    background-color: #fff;
    color: rgba(0, 0, 0, 0.85);
  }
  .air_invite .title {
    font-size: 16px;
    color: rgba(0, 0, 0, 0.85);
  }
  .fast-border {
    flex-direction: column;
    margin-top: 1.25rem;
  }
  .fast-border .fast-item {
    width: 100%;
    margin: 0 auto;
    padding: 1.25rem;
    margin-bottom: 1.5625rem;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
