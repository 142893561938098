.apps_card_box {
  width: calc(calc(100% / 3) - 2rem);
  margin-bottom: 2rem;
  margin-left: 1rem;
  margin-right: 1rem;
  box-sizing: border-box;
  color: black;
  /*width: 32%;*/
  /*width: 3.6rem;*/
  height: 20.75rem;
  border-radius: 0.5rem;
  padding: 0.4rem;
  /*margin-bottom: .2rem;*/
  border: 1px solid #0d87d7;
  background-color: white;
  box-shadow: 0px 10px 40px -12px rgba(0, 0, 0, 0.75);
}
.apps_card_box .pay_card_inline {
  padding: 0.2rem 0 0.2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  /*background: #fff;*/
  border-radius: 0.15rem;
}
.apps_card_box .pay_card_inline .box_logo {
  margin-top: 3rem;
}
.apps_card_box .pay_card_inline .box_logo > img {
  width: 6rem;
  height: 6rem;
}
.apps_card_box .pay_card_inline .text {
  margin-top: 1rem;
  height: 1rem;
}
.apps_card_box .pay_card_inline .do-btn {
  margin-top: 4rem;
  width: 100%;
}
@media screen and (max-width: 750px) {
  .apps_card_box {
    width: 100%;
    margin-top: 0.8rem;
  }
}
