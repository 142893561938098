.swap {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}
.swap .swap_container {
  width: 480px;
  padding-top: 150px;
  padding-bottom: 250px;
}
.swap .swap_container .swap_setup {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
  cursor: pointer;
}
.swap .swap_container .swap_setup > img {
  width: 20px;
  height: 20px;
}
.swap .swap_container .card {
  padding: 16px;
  border: 1px solid rgba(34, 34, 34, 0.05);
  border-radius: 20px;
}
.swap .swap_container .card:hover {
  border-color: rgba(34, 34, 34, 0.09);
  cursor: pointer;
}
.swap .swap_container .card .name {
  color: #7d7d7d;
  line-height: 24px;
}
.swap .swap_container .card .value {
  height: 59.2px;
  padding: 8px 0;
  display: flex;
  align-items: center;
}
.swap .swap_container .card .value .value_num {
  flex: 1;
  height: 100%;
  margin-right: 8px;
}
.swap .swap_container .card .value .value_num .input_num {
  width: 100%;
  height: 100%;
  font-size: 36px;
  -moz-appearance: textfield;
  border: none;
  color: #222;
  background-color: transparent;
  outline: none;
}
.swap .swap_container .card .value .value_num .input_num::-webkit-inner-spin-button,
.swap .swap_container .card .value .value_num .input_num::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.swap .swap_container .card .value .value_curr {
  display: flex;
}
.swap .swap_container .card .money_curr {
  font-size: 14px;
  color: #7d7d7d;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.swap .swap_container .card .money_curr .money_num {
  display: flex;
  align-items: center;
}
.swap .swap_container .card .money_curr .money_num .money_max {
  font-size: 12px;
  padding: 4px 6px;
  border-radius: 12px;
  background-color: #fef4ff;
  color: #fc72ff;
  margin-left: 4px;
}
.swap .swap_container .card .money_curr .money_num .money_max:hover {
  background-color: #feeaff;
}
.swap .swap_container .card .money_curr .money_num .money_max_disabled {
  background-color: rgba(34, 34, 34, 0.05);
}
.swap .swap_container .card_selected {
  border-color: transparent;
  background-color: #f9f9f9;
}
.swap .swap_container .card_selected:hover {
  border-color: transparent;
}
.swap .swap_container .card_sell {
  margin-bottom: 2px;
}
.swap .swap_container .card_buy {
  margin-top: 4px;
}
.swap .swap_container .exchange {
  position: relative;
  width: 100%;
}
.swap .swap_container .exchange .exchange_box {
  position: absolute;
  top: -50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 52px;
  height: 52px;
  border: 4px solid #fff;
  border-radius: 16px;
  background: #f9f9f9;
  display: flex;
  align-items: center;
  justify-content: center;
}
.swap .swap_container .exchange .exchange_box:hover {
  background: #f5f5f5;
  cursor: pointer;
}
.swap .swap_container .exchange .exchange_box:focus,
.swap .swap_container .exchange .exchange_box :active {
  opacity: 0.75;
}
.swap .swap_container .exchange .exchange_box:focus .exchange_icon,
.swap .swap_container .exchange .exchange_box :active .exchange_icon {
  transform: scale(0.9);
  color: #000;
}
.swap .swap_container .btn_container {
  width: 100%;
  height: 59px;
  margin-top: 6px;
  font-size: 18px;
}
.swap .swap_container .btn_container .link_wallet {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fef4ff;
  border-radius: 20px;
  color: #fc72ff;
  transition: all 0.1s ease-in-out;
  cursor: pointer;
}
.swap .swap_container .btn_container .link_wallet:hover {
  background: #feeaff;
}
.swap .swap_container .btn_container .link_wallet:focus,
.swap .swap_container .btn_container .link_wallet:active {
  transform: scale(0.98);
  opacity: 0.75;
}
.swap .swap_container .btn_container .enter_amount {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f9f9f9;
  color: #7d7d7d;
  cursor: default;
  border-radius: 20px;
}
.swap .swap_container .btn_container .review {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fc72ff;
  color: #fff;
  cursor: pointer;
  border-radius: 20px;
}
.swap .swap_container .btn_container .review:hover {
  background: #fd3cfe;
}
.swap .swap_container .btn_container .review:focus,
.swap .swap_container .btn_container .review:active {
  transform: scale(0.98);
  opacity: 0.75;
}
.swap .swap_container .btn_container .reviewing {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fc72ff;
  color: #fff;
  cursor: pointer;
  border-radius: 20px;
}
.swap .swap_container .more_info {
  margin-top: 12px;
  height: 30px;
  overflow: hidden;
  transition: all 0.3s;
}
.swap .swap_container .more_info .info_item {
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #7d7d7d;
  font-size: 14px;
}
.swap .swap_container .more_info .info_item .info_name {
  display: flex;
  align-items: center;
}
.swap .swap_container .more_info .info_item .info_name .tips_icon {
  width: 16px;
  height: 16px;
  margin-left: 4px;
}
.swap .swap_container .more_info .info_item .info_value {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.swap .swap_container .more_info .info_item .info_value .info_value_num {
  display: flex;
  align-items: center;
}
.swap .swap_container .more_info .info_item .info_value .info_value_num .info_value_tip {
  display: flex;
  align-items: center;
  opacity: 1;
  transition: all 0.1s;
}
.swap .swap_container .more_info .info_item .info_value .info_value_num .info_value_tip_hide {
  opacity: 0;
}
.swap .swap_container .more_info .info_item .info_value .info_value_num .trade_icon {
  width: 16px;
  height: 16px;
}
.swap .swap_container .more_info .info_item .info_value .arrow_icon {
  width: 16px;
  height: 16px;
  margin-left: 4px;
  transform: rotate(270deg);
  transition: all 0.2s ease-in-out;
}
.swap .swap_container .more_info .info_item .info_value .arrow_icon_show {
  transform: rotate(90deg);
}
.swap .swap_container .more_info .info_item .info_value .info_value_auto {
  height: 20px;
  padding: 0px 4px;
  background: rgba(34, 34, 34, 0.05);
  color: #7d7d7d;
  line-height: 20px;
  border-radius: 10px;
  margin-right: 5px;
}
.swap .swap_container .more_info .info_item .info_value .info_value_text {
  color: #222222;
}
.swap .swap_container .more_info_show {
  height: 180px;
}
.swap_tip {
  color: #7d7d7d;
  font-size: 12px;
}
.swap_tip p {
  line-height: 14px;
}
.swap_tip a {
  display: inline-block;
  color: #fc72ff;
  margin-top: 5px;
}
.swap_tip a:hover {
  text-decoration: none;
}
.swap_tip .slippage {
  height: 32px;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 8px;
  background: #f9f9f9;
  border-radius: 8px;
}
.swap_tip .slippage .slippage_text {
  color: #7d7d7d;
}
.swap_tip .slippage .slippage_value {
  color: #222222;
}
.setup_tip {
  max-width: 320px !important;
}
.setup_tip .ant-tooltip-inner {
  width: 320px;
  padding: 16px;
  background: #fff;
  color: #222;
  border-radius: 16px;
}
.setup_tip .ant-tooltip-inner .set_tip .set_tip_title {
  text-align: center;
  font-size: 18px;
}
.setup_tip .ant-tooltip-inner .set_tip .set_tip_item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
}
.setup_tip .ant-tooltip-inner .set_tip .set_tip_item .item_name {
  font-size: 16px;
}
.setup_tip .ant-tooltip-inner .set_tip .set_tip_item .item_value {
  height: 34px;
  padding: 4px;
  border: 1px solid rgba(34, 34, 34, 0.05);
  border-radius: 17px;
  display: flex;
  align-items: center;
  color: #7d7d7d;
}
.setup_tip .ant-tooltip-inner .set_tip .set_tip_item .item_value:active,
.setup_tip .ant-tooltip-inner .set_tip .set_tip_item .item_value:focus,
.setup_tip .ant-tooltip-inner .set_tip .set_tip_item .item_value:hover {
  border-color: rgba(252, 114, 255, 0.2);
}
.setup_tip .ant-tooltip-inner .set_tip .set_tip_item .item_value .auto {
  height: 24px;
  font-size: 14px;
  padding: 0px 6px;
  border-radius: 12px;
  background-color: #fef4ff;
  color: #fc72ff;
  margin-right: 4px;
  cursor: pointer;
}
.setup_tip .ant-tooltip-inner .set_tip .set_tip_item .item_value .set_tip_input {
  width: 42px;
  font-size: 14px;
  text-align: right;
  border: none;
  outline: none;
}
.setup_tip .ant-tooltip-inner .set_tip .set_tip_item .item_value .set_tip_input::-webkit-inner-spin-button,
.setup_tip .ant-tooltip-inner .set_tip .set_tip_item .item_value .set_tip_input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.setup_tip .ant-tooltip-inner .set_tip .close_btn {
  width: 100%;
  height: 47px;
  text-align: center;
  line-height: 47px;
  font-size: 18px;
  color: #000;
  margin-top: 16px;
  border-radius: 16px;
  background: #f4f4f4;
  cursor: pointer;
}
.setup_tip .ant-tooltip-inner .set_tip .close_btn:hover {
  background: #fafafa;
}
.setup_tip .ant-tooltip-inner .set_tip .close_btn:focus,
.setup_tip .ant-tooltip-inner .set_tip .close_btn:active {
  opacity: 0.75;
}
.confim_modal {
  width: 420px !important;
}
.confim_modal .header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #7d7d7d;
  font-size: 16px;
}
.confim_modal .header > img {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.confim_modal .token_item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;
  width: 100%;
}
.confim_modal .token_item .item_info {
  width: 80%;
  display: flex;
  flex-direction: column;
}
.confim_modal .token_item .item_info .num {
  color: #222;
  font-size: 24px;
  line-height: 32px;
}
.confim_modal .token_item .item_info .price {
  color: #7d7d7d;
  font-size: 16px;
  line-height: 24px;
}
.confim_modal .token_item .item_icon {
  display: flex;
  justify-content: center;
  align-items: center;
}
.confim_modal .token_item .item_icon > img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}
.confim_modal .token_arrow {
  margin-top: 16px;
}
.confim_modal .confim_info {
  margin-top: 16px;
}
.confim_modal .confim_info .confim_info_item {
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  color: #7d7d7d;
  margin-bottom: 8px;
}
.confim_modal .confim_info .confim_info_item span:last-child {
  color: #222;
}
.confim_modal .confim_info .confim_info_item .slippage {
  display: flex;
  align-items: center;
}
.confim_modal .confim_info .confim_info_item .slippage .auto {
  height: 20px;
  line-height: 20px;
  padding: 0 4px;
  border-radius: 10px;
  background-color: rgba(34, 34, 34, 0.05);
  margin-right: 5px;
}
.confim_modal .confim_btn {
  width: 100%;
  height: 59px;
  text-align: center;
  line-height: 59px;
  font-size: 18px;
  border-radius: 16px;
  background: #fc72ff;
  margin-top: 24px;
  color: #fff;
  cursor: pointer;
}
.confim_modal .confim_btn:hover {
  background: #fd3cfe;
}
.confim_modal .confim_btn:focus,
.confim_modal .confim_btn:active {
  transform: scale(0.98);
  opacity: 0.75;
}
@media screen and (max-width: 768px) {
  .swap {
    background: #222;
    padding: 0 10px;
  }
  .swap .swap_container {
    max-width: 480px;
  }
  .swap .swap_container .card {
    border: 1px solid rgba(255, 255, 255, 0.1);
    background: #424040;
  }
  .swap .swap_container .card:hover {
    border-color: rgba(34, 34, 34, 0.09);
    cursor: pointer;
  }
  .swap .swap_container .card .name {
    color: #fff;
  }
  .swap .swap_container .card .value .value_num .input_num {
    color: #fff;
  }
  .swap .swap_container .card .money_curr {
    color: #fff;
  }
  .swap .swap_container .card_selected {
    background-color: transparent;
  }
  .swap .swap_container .card_sell {
    margin-bottom: 2px;
  }
  .swap .swap_container .card_buy {
    margin-top: 4px;
  }
  .swap .swap_container .exchange .exchange_box {
    background: #424040;
  }
  .swap .swap_container .exchange .exchange_box .exchange_icon {
    color: #fff !important;
  }
  .swap .swap_container .exchange .exchange_box:hover {
    background: #424040;
    cursor: pointer;
  }
  .swap .swap_container .more_info .info_item {
    color: #fff;
  }
  .swap .swap_container .more_info .info_item .info_value .info_value_auto {
    height: 20px;
    padding: 0px 4px;
    background: #424040;
    color: #fff;
  }
  .swap .swap_container .more_info .info_item .info_value .info_value_text {
    color: #d4d3d3;
  }
  .setup_tip {
    max-width: 320px !important;
  }
  .setup_tip .ant-tooltip-inner {
    background: #222;
    color: #fff;
  }
  .setup_tip .ant-tooltip-inner .set_tip .set_tip_item .item_value {
    height: 34px;
    padding: 4px;
    border: 1px solid rgba(255, 255, 255, 0.3);
    color: #d4d3d3;
  }
  .setup_tip .ant-tooltip-inner .set_tip .set_tip_item .item_value .auto {
    background-color: #424040;
    color: #fc72ff;
  }
  .setup_tip .ant-tooltip-inner .set_tip .set_tip_item .item_value .set_tip_input {
    background: transparent;
    color: #fff;
  }
  .confim_modal {
    max-width: 420px !important;
    width: 100% !important;
    padding: 0 10px;
  }
  .confim_modal .ant-modal-content {
    background: #222;
  }
  .confim_modal .header {
    color: #fff;
  }
  .confim_modal .token_item .item_info .num {
    color: #fff;
  }
  .confim_modal .token_item .item_info .price {
    color: #fff;
  }
  .confim_modal .confim_info .confim_info_item {
    color: #fff;
  }
  .confim_modal .confim_info .confim_info_item span:last-child {
    color: #d4d3d3;
  }
  .confim_modal .confim_info .confim_info_item .slippage .auto {
    background-color: rgba(255, 255, 255, 0.3);
  }
}
