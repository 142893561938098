.eth-all {
  margin: 0 auto;
  padding: top 100px;
  min-height: calc(100vh - 0.64rem);
}
.eth-all .text {
  margin-top: 1.25rem;
  font-size: 1.625rem;
  font-weight: 700;
  margin-bottom: 1rem;
}
.eth-all .imageArea {
  display: flex;
  flex-flow: row wrap;
}
.eth-all .imageArea .imageMyItemDiv {
  position: relative;
  /* 将这个 div 设为相对定位，使其内部的子元素可以进行绝对定位 */
  display: inline-block;
  /* 使 div 自适应内容大小 */
}
.eth-all .imageArea .imageMyItemDiv .imageItem {
  display: block;
  /* 将图片设为块级元素，使其占据整行 */
  width: 5rem;
  border-radius: 5%;
  margin: 0.2rem;
}
.eth-all .imageArea .imageMyItemDiv .imageMyItem {
  border-radius: 5%;
  margin: 0.2rem;
}
.eth-all .imageArea .imageMyItemDiv p {
  position: absolute;
  /* 使用绝对定位将文本放在容器上 */
  top: 80%;
  /* 将文本移动到容器的中间位置 */
  left: 50%;
  /* 同上 */
  transform: translate(-50%, -50%);
  /* 通过此转换使文本完全居中 */
  margin: 0;
  /* 去除可能存在的外边距，确保文本完全居中 */
  color: white;
  /* 文本颜色设置为白色，可以根据需要调整 */
  padding: 2px 5px;
  /* 提供一些内边距，使文本更好看 */
}
.eth-all .imageArea .imageMyItemDiv .imageMyText {
  font-size: 2.2rem;
}
.eth-all .pageView {
  margin-top: 2rem;
}
.eth_modal_box .ant-modal-content .ant-modal-body .title {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 0.5rem;
  justify-content: center;
  margin-top: 1.25rem;
  font-size: 1.625rem;
  font-weight: 700;
}
.eth_modal_box .ant-modal-content .ant-modal-body .image_area {
  display: flex;
  flex-direction: row;
}
.eth_modal_box .ant-modal-content .ant-modal-body .image_area .image_pic {
  width: 15rem;
}
.eth_modal_box .ant-modal-content .ant-modal-body .image_area .image_desc {
  width: 13rem;
  margin-left: 1rem;
}
.eth_modal_box .ant-modal-content .ant-modal-body .image_area .image_desc .desc_item {
  overflow-wrap: break-word;
  margin-bottom: 0.4rem;
}
.eth_modal_box .ant-modal-content .ant-modal-body .close_handle_btn {
  margin-top: 1rem;
  margin-left: 0.2rem;
  padding: 0 10px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  font-size: 14px;
  border-radius: 5px;
  cursor: pointer;
  border: none;
  background: #5284ff;
  color: white;
}
@media screen and (max-width: 768px) {
  .eth-all {
    width: 100%;
    padding: 0 1.25rem;
    padding-top: 3.125rem;
    background-color: #0b002a;
    color: #fff;
  }
  .eth-all > .text {
    margin-top: 0.625rem;
    font-size: 20px;
    color: #fff;
  }
  .eth_modal_box .ant-modal-content .ant-modal-body .title {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 0.5rem;
    justify-content: center;
    margin-top: 1.25rem;
    font-size: 1.625rem;
    font-weight: 700;
  }
  .eth_modal_box .ant-modal-content .ant-modal-body .image_area {
    display: flex;
    flex-direction: column;
  }
  .eth_modal_box .ant-modal-content .ant-modal-body .image_area .image_pic {
    width: 100%;
  }
  .eth_modal_box .ant-modal-content .ant-modal-body .image_area .image_desc {
    width: 100%;
    margin-left: 1rem;
  }
  .eth_modal_box .ant-modal-content .ant-modal-body .image_area .image_desc .desc_item {
    overflow-wrap: break-word;
    margin-bottom: 0.4rem;
  }
  .eth_modal_box .ant-modal-content .ant-modal-body .close_handle_btn {
    margin-top: 1rem;
    margin-left: 0.2rem;
    padding: 0 10px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    font-size: 14px;
    border-radius: 5px;
    cursor: pointer;
    border: none;
    background: #5284ff;
    color: white;
  }
}
