.ens_handle_btn_disable {
  margin-left: 0.2rem;
  padding: 0 10px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  font-size: 14px;
  border-radius: 5px;
  cursor: pointer;
  background-color: #e5e5e5;
  border: 1px solid #333;
}
.ens_handle_btn {
  margin-left: 0.2rem;
  padding: 0 10px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  font-size: 14px;
  border-radius: 5px;
  cursor: pointer;
  border: none;
  background: #5284ff;
  color: white;
}
.ant-table-container {
  min-height: 24rem;
}
.table_user {
  display: flex;
  align-items: center;
}
.table_user .ant-image {
  margin-right: 0.3rem;
}
.TweetInfoArea {
  width: 90%;
  margin: 0 auto;
  font-size: 14px;
}
.TweetInfoArea .fast-border {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 2rem;
}
.TweetInfoArea .fast-border .fast-item {
  width: 100%;
  height: auto;
  padding: 1rem;
  border: 1px solid #0d87d7;
  border-radius: 0.625rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.TweetInfoArea .fast-border .fast-item .title {
  font-size: 1.125rem;
  font-weight: 700;
}
.TweetInfoArea .fast-border .fast-item .box_logo > img {
  width: 8rem;
}
.TweetInfoArea .fast-border .fast-item .text {
  margin-top: 0.625rem;
}
.TweetInfoArea .fast-border .fast-item .do-btn {
  margin-top: 0.625rem;
  width: 100%;
}
.TweetInfoArea .fast-border .fast-item .user-row-title {
  margin-bottom: 1.5rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-size: 0.9rem;
  font-weight: 800;
}
.TweetInfoArea .fast-border .fast-item .user-row {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.3rem;
  color: black;
}
.TweetInfoArea .fast-border .fast-item .user-row .row-left {
  display: flex;
}
.TweetInfoArea .fast-border .fast-item .user-row .row-left .user-avater {
  width: 2rem;
  height: 2rem;
}
.TweetInfoArea .fast-border .fast-item .user-row .row-left .left-info {
  margin-left: 0.6rem;
  font-size: 0.9rem;
  font-weight: 400;
}
.TweetInfoArea .fast-border .fast-space {
  width: 1rem;
}
.TweetInfoArea .history {
  width: 100%;
  margin-top: 50px;
}
.TweetInfoArea .history .pageDiv {
  display: flex;
  justify-content: end;
  margin-top: 2rem;
}
.TweetInfoArea .history .history_t {
  font-weight: bold;
}
.TweetInfoArea .history .table-list {
  width: 100%;
  margin-top: 10px;
}
@media screen and (max-width: 768px) {
  .table_btns {
    display: none;
  }
  .search-area {
    margin: 10px 0px;
    width: 100%;
    font-size: 18px;
  }
  .search-area .search-form {
    padding: 0 5px;
  }
  .search-area .earch_button {
    width: 80px;
    margin-left: 5px;
  }
  .ant-table {
    background-color: #0b002a;
  }
  .TweetInfoArea {
    width: 100%;
    padding: 0 20px;
    padding-top: 50px;
    background-color: #0b002a;
  }
}
