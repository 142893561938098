@import '~antd/dist/antd.css';

.head-content{
  display: flex;
  width: 1000px;
  justify-content: space-between;
  margin: 0 auto;
  padding: 20px 0px;
}

.header_title .title {
  white-space: nowrap;
  margin-left: 0.625rem;
  font-weight: 700;
}


@media screen and (max-width: 768px) {
  .head-content{
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin: 0 auto;
    padding: 20px 0px;
  }
}