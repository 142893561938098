.copy_handle_btn {
  width: 100px;
  margin-top: 0.5rem;
  padding: 0 10px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  font-size: 14px;
  border-radius: 5px;
  cursor: pointer;
  border: none;
  background: #5284ff;
  color: white;
}
.show {
  width: 68.75rem;
  margin: 0 auto;
  padding: top 100px;
  min-height: calc(100vh - 0.64rem);
}
.show a {
  color: #000;
  text-decoration: none;
}
.show .logo {
  text-align: center;
}
.show .logo > img {
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
}
.show .text {
  text-align: center;
  margin-top: 1.25rem;
  font-size: 1.625rem;
  font-weight: 700;
}
.show .fast-border {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 5rem;
}
.show .fast-border .fast-item {
  width: 33.75rem;
  height: auto;
  margin: 1.25rem;
  padding: 1.25rem;
  border: 1px solid #0d87d7;
  border-radius: 0.625rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.show .fast-border .fast-item .title {
  font-size: 1.125rem;
  font-weight: 700;
}
.show .fast-border .fast-item .box_logo {
  margin-top: 1.25rem;
}
.show .fast-border .fast-item .box_logo > img {
  width: 9.375rem;
}
.show .fast-border .fast-item .text {
  font-size: 1.625rem;
  margin-top: 0.625rem;
}
.show .fast-border .fast-item .btn {
  width: 12.5rem;
  height: 2.5rem;
  line-height: 2.5rem;
  border-radius: 0.3125rem;
  border: 1px solid #333;
  text-align: center;
  font-size: 1rem;
  cursor: pointer;
  margin-top: 1.25rem;
}
.show .fast-border .fast-item .btn_dis {
  cursor: no-drop;
}
.show .con {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 3.125rem;
}
.show .con .box {
  width: 68rem;
  height: auto;
  margin: 1.5625rem;
  padding: 1.25rem;
  border: 1px solid #0d87d7;
  border-radius: 0.625rem;
  display: flex;
  flex-direction: column;
}
.show .con .box .main {
  font-weight: bolder;
  margin-top: 2rem;
  margin-bottom: 0.5rem;
}
.show .con .box .title {
  margin-top: 0.5rem;
}
.show .bottom-area {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 3.125rem;
}
.show .bottom-area .bottom {
  width: 68rem;
  height: auto;
  margin: 1.5625rem;
  padding: 1.25rem;
  border: 1px solid #0d87d7;
  border-radius: 0.625rem;
  display: flex;
  flex-direction: column;
}
.show .bottom-area .bottom img {
  width: 100%;
}
.show .bottom-area .bottom .condescs {
  margin-top: 0.5rem;
}
.show .btn {
  width: 50rem;
  line-height: 2.5rem;
  border-radius: 0.3125rem;
  border: 1px solid #0d87d7;
  text-align: center;
  font-size: 1rem;
  cursor: pointer;
  margin-top: 1.25rem;
}
.show .btn_dis {
  cursor: no-drop;
}
@media screen and (max-width: 768px) {
  .show {
    width: 100%;
    padding: 0 1.25rem;
    padding-top: 3.125rem;
    background-color: #0b002a;
    color: #fff;
  }
  .show > .text {
    text-align: center;
    margin-top: 0.625rem;
    font-size: 20px;
    color: #fff;
  }
  .show .fast-border {
    flex-direction: column;
    margin-top: 1.25rem;
  }
  .show .fast-border .fast-item {
    width: 100%;
    height: 21.875rem;
    margin: 0 auto;
    padding: 1.25rem;
    margin-bottom: 1.5625rem;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .show .fast-border .fast-item .title {
    font-size: 24px;
    color: #555555;
  }
  .show .fast-border .fast-item .box_logo {
    margin-top: 2.5rem;
  }
  .show .fast-border .fast-item .box_logo > img {
    width: 6.25rem;
  }
  .show .fast-border .fast-item .text {
    font-size: 24px;
    margin-top: 0.9375rem;
    color: #555555;
  }
  .show .fast-border .fast-item .btn {
    width: 12.5rem;
    height: 2.5rem;
    line-height: 2.5rem;
    border-radius: 0.625rem;
    font-size: 16px;
    margin-top: 1.25rem;
    color: #555555;
  }
  .show .fast-border .fast-item .btn_dis {
    cursor: no-drop;
  }
  .show .con {
    flex-direction: column;
    margin-top: 1.25rem;
  }
  .show .con .box {
    color: #555555;
    width: 100%;
    margin: 0 auto;
    padding: 1.25rem;
    margin-bottom: 1.5625rem;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .show .con .box .title {
    color: #555555;
  }
  .show .con .box .text {
    margin-top: 0.9375rem;
  }
  .show .con .box .btn {
    width: 12.5rem;
    height: 2.5rem;
    line-height: 2.5rem;
    border-radius: 0.625rem;
    font-size: 16px;
    margin-top: 1.25rem;
    color: #555555;
  }
  .show .con .box .btn_dis {
    cursor: no-drop;
  }
  .show .bottom-area {
    flex-direction: column;
    margin-top: 1.25rem;
  }
  .show .bottom-area .bottom {
    color: #555555;
    width: 100%;
    margin: 0 auto;
    padding: 1.25rem;
    margin-bottom: 1.5625rem;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .show .bottom-area .bottom .condescs {
    margin-top: 0.5rem;
    white-space: normal;
    word-wrap: break-word;
  }
}
