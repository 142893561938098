.g_footer {
  width: 100%;
  height: 3.125rem;
  margin-top: 3.125rem;
  display: flex;
  align-items: center;
}
.g_footer .g_footer_link {
  width: 68.75rem;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.g_footer .g_footer_link .item_link {
  color: black;
  margin-right: 1.5rem;
}
.g_footer .g_footer_link .item_link > img {
  height: 1rem;
}
.g_footer .g_footer_link .disable_item {
  color: gray;
}
.g_footer .g_footer_link .link_b {
  display: none;
}
.g_footer .g_footer_link .medium > img,
.g_footer .g_footer_link .nh > img {
  height: 0.875rem;
}
.foot-copyright {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0.6rem;
  margin-bottom: 2rem;
}
.foot-copyright p {
  letter-spacing: -1px;
}
@media screen and (max-width: 768px) {
  .g_footer {
    height: 100%;
    padding: 0 0.625rem;
    margin-top: 0;
    background-color: #0b002a;
  }
  .g_footer .g_footer_link {
    height: 3.125rem;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .g_footer .g_footer_link .item_link {
    color: white;
  }
  .g_footer .g_footer_link .item_link > img {
    height: 1rem;
  }
  .g_footer .g_footer_link .link_h {
    display: none;
  }
  .g_footer .g_footer_link .link_b {
    display: block;
  }
  .g_footer .g_footer_link .medium > img,
  .g_footer .g_footer_link .nh > img {
    height: 0.875rem;
  }
}
