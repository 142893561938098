.airdrop {
  width: 68.75rem;
  margin: 0 auto;
  padding-top: 6.25rem;
  min-height: calc(100vh - 4rem);
  font-family: 'Franklin Gothic Medium', PingFang SC, PingFang SC-Medium !important;
}
.airdrop .logo {
  text-align: center;
}
.airdrop .logo > img {
  width: 7.5rem;
  height: 7.5rem;
  border-radius: 50%;
}
.airdrop .doge_num {
  text-align: center;
  line-height: 5rem;
  font-size: 1.75rem;
  font-weight: 700;
}
.airdrop .receive {
  display: flex;
  justify-content: center;
  margin-top: 1.875rem;
}
.airdrop .receive .receive_btn {
  height: 3.125rem;
  line-height: 3.125rem;
  padding: 0 3.125rem;
  border-radius: 0.125rem;
  border: 1px solid #333;
  cursor: pointer;
  font-size: 1rem;
}
.airdrop .receive .receive_btn_dis {
  background-color: #ccc;
  cursor: no-drop;
  border: none;
}
.airdrop .air_text {
  display: flex;
  justify-content: center;
  margin-top: 1.875rem;
}
.airdrop .air_text p {
  width: 37.5rem;
  font-size: 1rem;
}
.airdrop .air_table {
  width: 100%;
  margin-top: 1.875rem;
}
.airdrop .air_table .air_t {
  font-size: 1rem;
  font-weight: 700;
}
.airdrop .air_table .table {
  width: 100%;
  margin-top: 0.625rem;
  border: 1px solid #0e2c46;
  font-size: 1rem;
  border-radius: 0.3125rem;
}
.airdrop .air_table .table .thead {
  width: 100%;
  height: 3.75rem;
  background-color: #0e2c46;
  color: #fff;
}
.airdrop .air_table .table .thead .tr {
  width: 100%;
  display: flex;
}
.airdrop .air_table .table .thead .tr .td {
  width: 50%;
  text-align: center;
  line-height: 3.75rem;
  color: #fff;
  font-weight: 700;
}
.airdrop .air_table .table .tbody {
  height: 31.25rem;
  overflow: auto;
}
.airdrop .air_table .table .tbody::-webkit-scrollbar {
  width: 0;
}
.airdrop .air_table .table .tbody .tr {
  width: 100%;
  display: flex;
  height: 3.125rem;
  border-bottom: 1px solid #e5e5e5;
}
.airdrop .air_table .table .tbody .tr .td {
  width: 50%;
  text-align: center;
  line-height: 3.125rem;
}
@media screen and (max-width: 768px) {
  .airdrop {
    width: 100%;
    padding: 0.625rem;
    padding-top: 3.125rem;
  }
  .airdrop .logo > img {
    width: 6.25rem;
    height: 6.25rem;
  }
  .airdrop .doge_num {
    font-size: 20px;
  }
  .airdrop .receive .receive_btn {
    height: 2.1875rem;
    line-height: 2.1875rem;
    border-radius: 0.3125rem;
    font-size: 16px;
  }
  .airdrop .air_text p {
    width: 100%;
    font-size: 16px;
  }
  .airdrop .air_table .air_t {
    font-size: 16px;
  }
  .airdrop .air_table .table {
    font-size: 16px;
    border-radius: 0.3125rem;
    overflow: auto;
  }
  .airdrop .air_table .table::-webkit-scrollbar {
    width: 0;
  }
  .airdrop .air_table .table .thead {
    width: 31.25rem;
    height: 2.1875rem;
    color: #fff;
  }
  .airdrop .air_table .table .thead .tr {
    width: 100%;
    display: flex;
  }
  .airdrop .air_table .table .thead .tr .td {
    line-height: 2.1875rem;
  }
  .airdrop .air_table .table .tbody {
    width: 31.25rem;
    min-height: 6.25rem;
  }
  .airdrop .air_table .table .tbody .tr {
    height: 1.875rem;
  }
  .airdrop .air_table .table .tbody .tr .td {
    line-height: 1.875rem;
  }
}
