.BigPage {
  width: 100%;
  min-height: 50vh;
  padding-bottom: 0.2rem;
  position: relative;
  margin: 0 auto;
  margin-top: 1rem;
  display: flex;
  flex-wrap: wrap;
  /*padding-left: 1.2rem;*/
  /*padding-right: 1.2rem;*/
  /*margin-left: 2rem;*/
  /*margin-right: 2rem;*/
  color: black;
}
.pubTitel {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 2rem;
  margin-bottom: 2rem;
  font-size: 14px;
  font-weight: bold;
  margin-left: .6rem;
}
@media screen and (max-width: 750px) {
  .BigPage {
    margin-left: 0;
    padding-left: 0;
    margin-top: 0.6rem;
    color: white;
  }
}
