.normal_handle_btn_disable {
  margin-left: 0.2rem;
  line-height: 50px;
  text-align: center;
  font-size: 14px;
  border-radius: 5px;
  cursor: pointer;
  background-color: #e5e5e5;
  border: 1px solid #333;
}
.normal_handle_btn {
  margin-left: 0.2rem;
  line-height: 50px;
  text-align: center;
  font-size: 14px;
  border-radius: 5px;
  cursor: pointer;
  border: none;
  background: #5284ff;
  color: white;
}
.ant-select-selector {
  height: 100%;
}
.table_btns {
  width: 120px;
}
.sub_title {
  font-weight: bold;
}
.create_dumb_area {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}
.create_dumb_area .reg_item_row {
  margin-top: 30px;
}
.create_dumb_area .reg_item_row_div {
  margin-top: 40px;
  margin-bottom: 40px;
}
.create_dumb_area .reg_item_row_div .reg_item_row_btns {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 10px;
}
.create_dumb_area .did-reg-form0 {
  flex: 1;
  display: flex;
  width: 100%;
  height: 50px;
  border-radius: 5px;
  border: 1px solid #333;
  padding: 0px 20px;
}
.create_dumb_area .did-reg-form0 input {
  padding-left: 10px;
  width: 100%;
  border: none;
  border-radius: 0;
  font-family: Overpass;
  font-weight: 100;
}
.create_dumb_area .did-reg-form0 input:focus {
  outline: 0;
}
.create_dumb_area .did-reg-form0 input::-webkit-input-placeholder {
  color: gray;
}
.create_dumb_area .did-reg-form0 .end {
  text-align: center;
  height: 50px;
  line-height: 50px;
}
.create_dumb_area .earch_button {
  margin-left: 20px;
  border-radius: 5px;
  border: 1px solid #333;
  background: #5284ff;
  color: white;
  font-family: Overpass;
  width: 162px;
  border: none;
  overflow: hidden;
}
.create_dumb_area .earch_button:hover {
  cursor: pointer;
}
.ensset_area {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  margin-bottom: 40px;
}
.ensset_area .reg_item_row {
  margin-top: 30px;
}
.ensset_area .reg_item_row_div {
  margin-top: 40px;
  margin-bottom: 40px;
}
.ensset_area .reg_item_row_div .reg_item_row_btns {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 10px;
}
.ensset_area .did-reg-form {
  flex: 1;
  display: flex;
  width: 100%;
  height: 50px;
  border-radius: 5px;
  border: 1px solid #333;
  padding: 0px 20px;
  align-items: center;
}
.ensset_area .did-reg-form input {
  color: black;
  padding-left: 10px;
  width: 100%;
  border: none;
  border-radius: 0;
  font-family: Overpass;
  font-weight: 100;
  background-color: transparent;
}
.ensset_area .did-reg-form input:focus {
  outline: 0;
}
.ensset_area .did-reg-form input::-webkit-input-placeholder {
  color: gray;
}
.ensset_area .did-reg-form button {
  background-color: #1890ff;
  text-align: center;
  width: 120px;
  height: 40px;
  line-height: 40px;
  align-items: center;
}
.ensset_area .did-reg-form .ant-switch-checked .ant-switch-handle {
  left: calc(100% - 18px - 10px);
}
.ensset_area .did-reg-form .ant-switch .ant-switch-handle {
  margin-left: 2px;
  top: 7px;
  width: 22px;
  height: 22px;
}
.ensset_area .earch_button {
  margin-left: 20px;
  border-radius: 5px;
  border: 1px solid #333;
  background: #5284ff;
  color: white;
  font-family: Overpass;
  width: 162px;
  border: none;
  overflow: hidden;
}
.ensset_area .earch_button:hover {
  cursor: pointer;
}
.btn_area {
  margin-top: 70px;
}
.ant-table-cell {
  text-align: center;
}
#stdDomainTable .ant-table-cell {
  text-align: left;
}
.ant-table .ant-table-container {
  border-radius: 10px;
  overflow: auto;
  border: 1px solid #0e2c46;
}
.ant-table .ant-table-container .ant-table-content table {
  background-color: #fff;
}
.ant-table .ant-table-container .ant-table-content table .ant-table-thead {
  background-color: #0e2c46;
  overflow: auto;
  color: #fff;
}
.ant-table .ant-table-container .ant-table-content table .ant-table-thead tr th {
  text-align: center;
  background-color: transparent;
  color: #fff;
}
.ant-table .ant-table-container .ant-table-content table .ant-table-tbody tr td {
  text-align: center;
  border: none;
}
.didreg_div {
  width: 100%;
  margin: 0 auto;
  padding-top: 10px;
  font-size: 14px;
}
.didreg_div .ens_title {
  text-align: center;
  margin-top: 3.5rem;
  font-weight: bolder;
  font-size: 1.2rem;
  margin-bottom: 0.1rem;
}
.didreg_div .ens_sub_title {
  text-align: center;
  margin-top: 2.5rem;
  font-size: 1rem;
}
.didreg_div .title {
  margin-top: 5rem;
  margin-bottom: 10px;
  font-weight: bold;
}
.didreg_div .did_reg_div {
  width: 100%;
  padding: 20px 50px;
  border-radius: 5px;
  border: 1px solid #333;
}
.didreg_div .did_reg_div .area_row_input {
  display: flex;
  margin-top: 0.2rem;
  height: 40px;
  line-height: 40px;
  align-items: center;
}
.didreg_div .did_reg_div .area_row_input .modal_input {
  display: flex;
  flex: 1;
  margin-left: 0.2rem;
  border: 0.01rem solid gray;
  height: 40px;
  line-height: 40px;
  border-radius: 0.1rem;
  background-color: white;
  color: black;
  padding-left: 0.2rem;
}
.didreg_div .did_reg_div .area_row_input .fee_btn {
  width: 0.6rem;
}
.didreg_div .did_reg_div .area_row_input .modal_progress {
  margin-left: 0.1rem;
}
.didreg_div .did_reg_div .area_check_view .title {
  margin-top: 0.3rem;
  display: flex;
  height: 40px;
  line-height: 40px;
}
.didreg_div .did_reg_div .area_check_view .row {
  display: flex;
  margin-top: 0.2rem;
  margin-left: 1rem;
  line-height: 24px;
}
.didreg_div .did_reg_div .area_check_view .row .minWidth {
  width: 180px;
}
.didreg_div .did_reg_div .area_check_view .row .modal_progress {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 0.2rem;
}
.didreg_div .to_oldair_area {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: right;
  margin-top: 20px;
}
.didreg_div .to_oldair_area .to_old_airdrop {
  width: 300px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  font-size: 14px;
  border-radius: 5px;
  margin-top: 10px;
  right: 50px;
  background: #5284ff;
  color: white;
}
.didreg_div .history {
  width: 100%;
  margin-top: 50px;
}
.didreg_div .history .history_t {
  font-weight: bold;
}
.didreg_div .history .table-list {
  width: 100%;
  margin-top: 10px;
}
.didreg_div .air_invite_div {
  margin-top: 50px;
  width: 100%;
  padding: 20px 50px;
  border-radius: 5px;
  border: 1px solid #333;
}
.didreg_div .air_invite_div .copy {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.didreg_div .air_invite_div .copy .copy_btn {
  width: 100px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 5px;
  margin-top: 20px;
  cursor: pointer;
  background: #5284ff;
  color: white;
}
.didreg_div .no_reg_div {
  margin-top: 50px;
  width: 100%;
  border-radius: 5px;
  border: 1px solid #333;
  display: flex;
  flex-direction: column;
  padding: 20px 50px;
}
.didreg_div .no_reg_div .no_reg_top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.didreg_div .no_reg_div .no_reg_top .text {
  flex: 1;
  padding-right: 50px;
}
.didreg_div .no_reg_div .no_reg_top .btn {
  width: 100px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 5px;
  background: #5284ff;
  color: white;
  cursor: pointer;
}
.didreg_div .no_reg_div .error_msg {
  margin-top: 20px;
}
.ant-table-wrapper .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-container .ant-table-content .table_copy {
  color: blue;
  text-align: center;
  display: flex;
  flex-direction: column;
}
.ant-table-wrapper .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-container .ant-table-content .table_copy .copy_btn {
  width: 7.5rem;
}
.ant-table-wrapper .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-container .ant-table-content .table_btns {
  display: flex;
  flex-direction: column;
}
.ant-table-wrapper .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-container .ant-table-content .table_btns a {
  color: blue;
}
.ant-table-wrapper .ant-spin-nested-loading .ant-spin-container .ant-table .ant-table-container .ant-table-content .table_btns .disable_btn {
  color: gray;
}
.rule_modal_box .ant-modal-content .ant-modal-body .title {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 0.5rem;
  justify-content: center;
}
.rule_modal_box .ant-modal-content .ant-modal-body .box .vote_type {
  margin-bottom: 0.5rem;
}
.rule_modal_box .ant-modal-content .ant-modal-body .box .vote_year {
  margin-bottom: 0.5rem;
}
.rule_modal_box .ant-modal-content .ant-modal-body .box .vote_price {
  margin-bottom: 0.5rem;
}
@media screen and (max-width: 768px) {
  input {
    color: #000000;
  }
  .didreg_div {
    width: 100%;
    padding: 0 20px;
    padding-top: 50px;
    background-color: #0b002a;
    color: white;
  }
  .didreg_div .ens_title {
    color: white;
  }
  .didreg_div .title {
    color: white;
  }
  .didreg_div .did_reg_div {
    color: white;
    padding: 16px;
  }
  .didreg_div .did_reg_div .new_nft .ensset_area {
    margin: 10px 0px;
    width: 100%;
  }
  .didreg_div .did_reg_div .new_nft .ensset_area .reg_item_row .vote_year .ant-radio-group .ant-radio-wrapper span {
    color: white;
  }
  .didreg_div .did_reg_div .new_nft .ensset_area .reg_item_row_div {
    margin-top: 40px;
    margin-bottom: 20px;
  }
  .didreg_div .did_reg_div .new_nft .ensset_area .reg_item_row_div .reg_item_row_btns {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .didreg_div .did_reg_div .new_nft .ensset_area .reg_item_row_div .reg_item_row_btns .ens_reg_url {
    margin-top: 10px;
  }
  .didreg_div .did_reg_div .new_nft .ensset_area .did-reg-form {
    padding: 0 5px;
  }
  .didreg_div .did_reg_div .new_nft .ensset_area .did-reg-form input {
    color: white;
    font-weight: bolder;
  }
  .didreg_div .did_reg_div .new_nft .ensset_area .did-reg-form .modal_input::-webkit-input-placeholder {
    color: white;
  }
  .didreg_div .did_reg_div .new_nft .ensset_area .earch_button {
    width: 130px;
    margin-left: 5px;
  }
  .didreg_div .did_reg_div .title {
    color: white;
  }
  .didreg_div .history {
    overflow: hidden;
  }
  .didreg_div .history .history_t {
    color: #fff;
  }
  .didreg_div .air_invite_div {
    padding: 16px;
    background-color: #fff;
    color: rgba(0, 0, 0, 0.85);
  }
  .didreg_div .air_invite_div .title {
    color: rgba(0, 0, 0, 0.85);
  }
  .didreg_div .air_invite_div .copy {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
  }
  .didreg_div .air_invite_div .copy .invite_link {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.85);
    word-break: break-word;
  }
  .didreg_div .air_invite_div .copy .copy_btn {
    width: auto;
    padding: 0 1.25rem;
    height: 2.5rem;
    line-height: 2.5rem;
    font-size: 14px;
    background-color: #fff;
  }
  .didreg_div .no_reg_div {
    padding: 20px 10px;
    background-color: white;
  }
  .didreg_div .no_reg_div .no_reg_top .text {
    padding-right: 1.25rem;
  }
  .didreg_div .no_reg_div .no_reg_top .btn {
    width: 5rem;
    height: 2.5rem;
    line-height: 2.5rem;
    font-size: 14px;
  }
  .table_btns {
    display: none;
  }
  .ant-table {
    background-color: #0b002a;
  }
}
