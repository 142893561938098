.apps-space {
  margin-top: 2rem;
  width: 100%;
  display: flex;
  height: 2rem;
}
.appContents {
  width: 100%;
  margin-bottom: 5rem;
  margin-left: 1rem;
  margin-right: 1rem;
}
@media screen and (max-width: 750px) {
  .apps-space {
    margin-top: 0.2rem;
  }
  .pubTitel {
    color: white;
  }
}
