.x20footerDiv {
  padding: 0 10px;
  width: 100%;
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.x20footerDiv .x20footerLint {
  margin-bottom: 2rem;
}
.x20footerDiv .x20footerLint a {
  color: black;
  margin-right: 3rem;
}
.x20footerDiv .x20footer {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.x20footerDiv .x20footer .left-copyright {
  display: flex;
  justify-content: center;
  align-items: center;
}
.x20footerDiv .x20footer .left-copyright p {
  letter-spacing: -1px;
  margin-left: 5px;
}
.x20footerDiv .x20footer .right-desc {
  font-size: 12px;
}
@media screen and (max-width: 768px) {
  .x20footerDiv .x20footerLint a {
    color: white;
  }
  .x20footerDiv .x20footer {
    color: white;
    flex-direction: column;
  }
  .x20footerDiv .x20footer .left-copyright {
    width: 100%;
    align-items: start;
    justify-content: start;
    text-align: start;
    margin-bottom: 1rem;
  }
  .x20footerDiv .x20footer .right-desc {
    font-size: 10px;
  }
}
