.TweetLogin .login-info .unlogin {
  display: flex;
  justify-content: center;
  align-content: center;
}
.TweetLogin .login-info .project-divs .user-area {
  display: flex;
  justify-content: space-between;
}
.TweetLogin .login-info .project-divs .user-area .user-info {
  display: flex;
  flex-direction: column;
}
.TweetLogin .login-info .project-divs .user-area .user-info .row-left {
  display: flex;
}
.TweetLogin .login-info .project-divs .user-area .user-info .row-left .user-avater {
  width: 3rem;
  height: 3rem;
}
.TweetLogin .login-info .project-divs .user-area .user-info .row-left .user-infos {
  display: flex;
}
.TweetLogin .login-info .project-divs .user-area .user-info .row-left .user-infos .left-info {
  margin-left: 0.6rem;
  font-size: 0.9rem;
  font-weight: 400;
}
.TweetLogin .login-info .project-divs .user-area .user-info .row-left .user-infos .right-info {
  margin-left: 1rem;
}
.TweetLogin .login-info .project-divs .user-area .user-info .eth-area {
  display: flex;
  margin-top: 0.25rem;
}
.TweetLogin .login-info .project-divs .user-area .user-info .eth-area > img {
  width: 1.2rem;
}
.TweetLogin .login-info .project-divs .user-area .user-info .eth-area .proj-total {
  margin-left: 2rem;
}
.TweetLogin .login-info .project-divs .user-area .user-info .eth-area .eth-name {
  margin-left: 0.3rem;
  text-decoration-line: underline;
}
.TweetLogin .login-info .project-divs .user-area .user-info .eth-area .eth-name:hover {
  cursor: pointer;
  text-decoration-line: underline;
  color: blue;
}
.TweetLogin .login-info .project-divs .user-area .right-info {
  align-items: end;
}
.TweetLogin .air_invite {
  margin-top: 3rem;
  width: 100%;
}
.TweetLogin .air_invite .area_row_input {
  display: flex;
  margin-top: 0.2rem;
  height: 40px;
  line-height: 40px;
  align-items: center;
}
.TweetLogin .air_invite .area_row_input .modal_input {
  margin-left: 0.2rem;
  border: 0.01rem solid gray;
  height: 40px;
  line-height: 40px;
  border-radius: 0.1rem;
  background-color: white;
  color: black;
  padding-left: 0.2rem;
}
.TweetLogin .air_invite .area_row_input .modal_progress {
  margin-left: 0.1rem;
}
.TweetLogin .air_invite .area_check_view .title {
  margin-top: 0.3rem;
  display: flex;
  height: 40px;
  line-height: 40px;
}
.TweetLogin .air_invite .area_check_view .row {
  display: flex;
  margin-top: 0.2rem;
  margin-left: 1rem;
  line-height: 24px;
}
.TweetLogin .air_invite .area_check_view .row .minWidth {
  width: 280px;
}
.TweetLogin .air_invite .area_check_view .row .modal_progress {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 0.2rem;
}
@media screen and (max-width: 768px) {
  .TweetLogin .air_invite {
    margin-top: 1rem;
    margin-bottom: 1rem;
    width: 100%;
    padding: 16px;
    background-color: #fff;
    color: rgba(0, 0, 0, 0.85);
  }
  .TweetLogin .air_invite .title {
    font-size: 16px;
    color: rgba(0, 0, 0, 0.85);
  }
}
